import React, { FC, useEffect, useState } from 'react';
import './styles.css';

const Manutencao: FC = () => {
    const [horarioInicial, setHorarioInicial] = useState('');
    const [horarioFinal, setHorarioFinal] = useState('');

    const calculateHorario = (timestamp) => {
        const date = new Date(timestamp * 1000);
        const minutes = date.getMinutes();
        const roundedMinutes = Math.floor(minutes / 30) * 30;
        date.setMinutes(roundedMinutes, 0, 0);
        return Math.floor(date.getTime() / 1000);
    };

    const formatHorario = (horarioStamp: number) => {
        if (!isNaN(horarioStamp)) {
            const horarioDate = new Date(horarioStamp * 1000);
            const horarioDia = horarioDate.getDate().toString().padStart(2, '0');
            const horarioMes = (horarioDate.getMonth() + 1).toString().padStart(2, '0');
            const horarioAno = horarioDate.getFullYear();
            const horarioHora = horarioDate.getHours().toString().padStart(2, '0');
            const horarioMinuto = horarioDate.getMinutes().toString().padStart(2, '0');
            return `${horarioDia}/${horarioMes}/${horarioAno} às ${horarioHora}:${horarioMinuto}`;
        }
        return '';
    }
    useEffect(() => {
        const inicioHorarioStamp = parseInt(process.env.REACT_APP_INICIO_HORARIO, 10);
        const fimHorarioStamp = parseInt(process.env.REACT_APP_HORARIO_FINAL, 10);
        const horarioInicialString = formatHorario(calculateHorario(Math.floor(Date.now() / 1000)));
        const horarioFinalString = formatHorario(calculateHorario(Math.floor(Date.now() / 1000) + 3600));
        setHorarioInicial(horarioInicialString);
        setHorarioFinal(horarioFinalString);
        if (!isNaN(inicioHorarioStamp)) {
            const horarioInicial = formatHorario(inicioHorarioStamp);
            setHorarioInicial(horarioInicial);
        }

        if (!isNaN(fimHorarioStamp)) {
            const horarioFinal = formatHorario(fimHorarioStamp);
            setHorarioFinal(horarioFinal);
        }
    }, []);
    return (
        <div className='page-container'>
            <div className='page-header'>
                <img className="seb-wave-bg" alt='bg' src="./img/backgrounds/wave.svg" />
                <div className='seb-absolute-title'>MANUTENÇÃO PROGRAMADA</div>
            </div>
            <div className='paragraph-container'>
                <div className='paragraph'>Informamos que nosso sistema estará indisponível no dia <strong>{horarioInicial}</strong> até <strong>{horarioFinal}</strong> devido a manutenção programada</div>
                <div className='paragraph'>Durante esse período, não será possível acessar as funcionalidades do sistema</div>
                <div className='paragraph'>Pedimos desculpas pelo inconveniente que isso possa causar e agradecemos a compreensão</div>
                <div className='paragraph'>Novo Endereço do CRM: <a className='urlText' target='blank' href='https://seb.crm.dynamics.com/'>seb.crm.dynamics.com</a></div>
            </div>
            <img className="seb-logo" alt='bg' src="./img/seb-logo.png" />
        </div>
    );
}


export default Manutencao;