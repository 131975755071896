import React from 'react';
import Manutencao from './pages/Manutencao/index';

export const AppContext = React.createContext({
	BlockedResources: null,
});

function App() {
	return (<Manutencao />);
}

export default App;

